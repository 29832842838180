"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnrollmentFieldDataType = exports.EnrollmentFieldActionType = exports.isChildScheduleSurveyOrEndAndSendSurvey = exports.isChildEndAndSendSurvey = exports.isChildEscalationType = exports.isChildSurveyType = void 0;
function isChildSurveyType(params) {
    return params.type === "survey";
}
exports.isChildSurveyType = isChildSurveyType;
function isChildEscalationType(params) {
    return params.type === "escalation";
}
exports.isChildEscalationType = isChildEscalationType;
function isChildEndAndSendSurvey(params) {
    return params.type === "endAndSendSurvey";
}
exports.isChildEndAndSendSurvey = isChildEndAndSendSurvey;
function isChildScheduleSurveyOrEndAndSendSurvey(params) {
    return params.type === "survey" || params.type === "endAndSendSurvey";
}
exports.isChildScheduleSurveyOrEndAndSendSurvey = isChildScheduleSurveyOrEndAndSendSurvey;
var EnrollmentFieldActionType;
(function (EnrollmentFieldActionType) {
    EnrollmentFieldActionType["SET_DATE_NOW"] = "SET_DATE_NOW";
    EnrollmentFieldActionType["SET_VALUE"] = "SET_VALUE";
    EnrollmentFieldActionType["SET_RESPONSE_VALUE"] = "SET_RESPONSE_VALUE";
})(EnrollmentFieldActionType = exports.EnrollmentFieldActionType || (exports.EnrollmentFieldActionType = {}));
var EnrollmentFieldDataType;
(function (EnrollmentFieldDataType) {
    EnrollmentFieldDataType["STRING"] = "string";
    EnrollmentFieldDataType["BOOLEAN"] = "boolean";
    EnrollmentFieldDataType["NUMBER"] = "number";
    EnrollmentFieldDataType["DATE"] = "dateonly";
    EnrollmentFieldDataType["DATE_TIME"] = "dateTime";
    EnrollmentFieldDataType["INTEGER"] = "integer";
    EnrollmentFieldDataType["DECIMAL"] = "decimal";
    EnrollmentFieldDataType["RELATIONSHIP"] = "relationship";
})(EnrollmentFieldDataType = exports.EnrollmentFieldDataType || (exports.EnrollmentFieldDataType = {}));
